import { useState, useEffect, useCallback, useMemo } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { CodeTerminal } from './CodeTerminal';
import headerImg from "../assets/img/Headhot.webp";
import 'animate.css';


export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(20 - Math.random() * 100);

  const toRotate = useMemo(() => [ 
    "Web Development", 
    "Wordpress", 
    "Social Media Ads", 
    "Bespoke Web Apps", 
    "E-commerce Solutions", 
    "SEO Optimization", 
    "UI/UX Design"
  ], []);

  const period = 500;

  const tick = useCallback(() => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setDelta(50);
    }
  }, [loopNum, toRotate, isDeleting, text.length]);

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text, delta, tick]);

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={6} xl={6}>
            <h1>{`Hi, I'm Joe Braybrook,`}<br></br>I'm great at <br></br><span className="txt-rotate" dataperiod="1000" data-rotate={toRotate}><span className="wrap">{text}</span></span></h1>
            <p>
              I craft the digital tools essential for your online success. <br /><br />
              With over a decade of experience in web development,  <br /> tailoring solutions to your unique challenges is what I love. <br />
              Reach out for a personalized consultation.
            </p>
          </Col>
          <Col xs={12} md={6} xl={6}>
            <img id="headshot" src={headerImg} alt="Header Img"/> 
            <CodeTerminal/>       
          </Col>
        </Row>
      </Container>
    </section>
  )
}
