import React from 'react';
import { useState } from 'react';
import netlifyIcon from '../assets/img/netlify.svg';
import digitaloceanIcon from '../assets/img/digitalocean.svg';
import tailwindIcon from '../assets/img/tailwind.svg';
import mailchimpIcon from '../assets/img/mailchimp.svg';
import woocommerceIcon from '../assets/img/woocommerce.svg';
import wordpressIcon from '../assets/img/wordpress.svg';
import jsIcon from '../assets/img/js.svg';
import css3Icon from '../assets/img/css-3.svg';
import htmlIcon from '../assets/img/html.svg';
import breakdanceIcon from '../assets/img/breakdance-logo.svg';
// import Spline from '@splinetool/react-spline';

const TechSection = () => {
  const [showSimpleExplanations, setShowSimpleExplanations] = useState(false);

  const toggleSimpleExplanations = () => {
    setShowSimpleExplanations(!showSimpleExplanations);
  };

  const technologies = [
    {
      name: 'HTML5',
      icon: htmlIcon,
      group: 'Basic Web Structure',
      description: showSimpleExplanations
        ? "HTML is like the skeleton of a house that holds everything together, providing the structure and layout for web content."
        : "A markup language used for structuring and presenting content on the World Wide Web.",
    },
    {
      name: 'CSS3',
      icon: css3Icon,
      group: 'Website Styling',
      description: showSimpleExplanations
        ? "Think of it as the paint and decorations that make a house look good, controlling how web elements like text, colors, and spacing appear on a webpage."
        : "A style sheet language used for describing the presentation of a document written in a markup language such as HTML.",
    },
    {
      name: 'JavaScript',
      icon: jsIcon,
      group: 'Interactive Features',
      description: showSimpleExplanations
        ? "It's like the wiring that makes a house's lights and appliances work, enabling things like interactive forms, animations, and dynamic content on websites."
        : "A programming language that conforms to the ECMAScript specification.",
    },
    {
      name: 'Tailwind CSS',
      icon: tailwindIcon,
      group: 'Customizable Styles',
      description: showSimpleExplanations
        ? "It makes customizing website styles easy, letting you quickly pick and choose styles like you would pick out clothes."
        : "A utility-first CSS framework packed with classes that can be composed to build any design, directly in your markup.",
    },
    {
      name: 'DigitalOcean',
      icon: digitaloceanIcon,
      group: 'Website Hosting',
      description: showSimpleExplanations
        ? "It's a service for putting websites on the internet, like a plot of land where you can build your website 'house'."
        : "An American cloud infrastructure provider that offers cloud services to help deploy modern apps.",
    },
    {
      name: 'Netlify',
      icon: netlifyIcon,
      group: 'Simplified Hosting',
      description: showSimpleExplanations
        ? "It's a tool for hosting websites with less hassle, offering a simple way to get your website online."
        : "A powerful serverless platform with an intuitive git-based workflow, offering automated deployments, shareable previews, and much more.",
    },
    {
      name: 'WordPress',
      icon: wordpressIcon,
      group: 'Content Management',
      description: showSimpleExplanations
        ? "It helps easily manage website content, like having a neat system to organize all your website 'stuff'."
        : "A free and open-source content management system based on PHP and MySQL.",
    },
    {
      name: 'Breakdance',
      icon: breakdanceIcon,
      group: 'Content Management',
      description: showSimpleExplanations
        ? "Breakdance is a superior WordPress builder, combining the best of Elementor and Divi with enhanced speed, integration, and customization."
        : "Breakdance is a next-generation website builder for WordPress, outperforming both Elementor and Divi in speed, WooCommerce integration, and dynamic capabilities.",
    },
    {
      name: 'WooCommerce',
      icon: woocommerceIcon,
      group: 'Online Stores',
      description: showSimpleExplanations
        ? "It's a tool for setting up online shops on websites, turning your website into a virtual store."
        : "An open-source e-commerce plugin for WordPress, allowing for the integration of e-commerce functionalities in WordPress websites.",
    },
    {
      name: 'Mailchimp',
      icon: mailchimpIcon,
      group: 'Email Marketing',
      description: showSimpleExplanations
        ? "It helps manage and send emails to customers and subscribers, like a digital postman that you can schedule beautiful emails with."
        : "An all-in-one Marketing Platform that facilitates communication with clients, customers, and other interested parties through various marketing strategies and tools.",
    },
  ];
  


      return (

        
        <div id="icons-section" className="icons-section bg-dark text-white">
           {/* <Spline scene="https://prod.spline.design/ySsDhxyivVyZx6PP/scene.splinecode" /> */}
          <h1 className="centered-text"><span>{showSimpleExplanations ? 'My Weapons Of Choice' : 'Web Development Technologies'} </span></h1>
          <p className="centered-text">I leverage a range of potent technologies to breathe life into your projects.<br></br> 
Whether I'm crafting a website from the ground up or translating mockup designs, rest assured, I'm committed to delivering top-tier websites<br></br> 
I make use of cutting-edge technologies to guarantee speed, reliability, and responsiveness across all devices.</p>
          
          <button className={`toggle-button${showSimpleExplanations ? ' active' : ''}`} onClick={toggleSimpleExplanations}>
            <span>{showSimpleExplanations ? 'Technical Explainations' : 'Ok Joe, but what does that mean in english?'} </span>
          </button>
          <div className="icons-container">
            {technologies.map((tech, index) => (
              <div key={tech.name} className="icon-card glow-on-hover">
                <img src={tech.icon} alt={tech.name} className="icon-image"/>
                <p className="icon-name">{tech.name}</p>
                <p className="icon-desc">{tech.description}</p>
              </div>
            ))}
          </div>
        </div>
      );
    };

export default TechSection;