import { Container, Row, Col } from "react-bootstrap";
// import { MailchimpForm } from "./MailchimpForm";
// import logo from "../assets/img/JoeSBDev.png";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";
import logo from '../assets/img/fullstacklogo.webp';

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          {/* <MailchimpForm /> */}
          <Col size={12} sm={6}>
       
         
          <img id="logo" src={logo} alt="FullStackJoe_Logo"/>
         
         
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
                <a href="https://www.linkedin.com/in/joebraybrook/" aria-label="Joe's LinkedIn Profile"><img src={navIcon1} alt="LinkedIn Icon" /></a>
                <a href="https://twitter.com/FullStackJoeDev" aria-label="Joe's Twitter Profile"><img src={navIcon2} alt="Twitter Icon" /></a>
                <a href="https://www.instagram.com/fullstackjoe/" aria-label="Joe's Instagram Profile"><img src={navIcon3} alt="Instagram Icon" /></a>
            </div>
            <p>Handcrafted with React, JS, and SCSS.
                <br /><br />
                © fullstackjoe 2023</p>
          </Col>


          
        </Row>
       
      </Container>
    </footer>
  )
}
